import React, { Component, createRef } from "react";
import { injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import Layout from "../../shared/Layout";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import Auth from "../../shared/Auth";
import withRouter from "../../../util/withRouter";
import appState from "../../../state/AppStateContainer";
import HeaderTitle from "../../shared/HeaderTitle";
import { withAuthContext } from "../../../state/AuthContext";
import EmailContent from "./components/EmailContent";
import services from "../../../services";
import { notificationsData } from "./utils/utils";
import * as Yup from "yup";

export class EmailNotifications extends Component {
  constructor() {
    super();
    this.formik = createRef();
    this.emailNotificationsTypes = [
      "serviceAdvisorNotifications",
      "FastCollectSatisfiedCustomerServiceAdvisor",
      "FastCollectNotSatisfiedCustomerServiceAdvisor",
      "forgotPasswordEmail",
      "setPasswordScreenForAdmin",
      "orderRefundEmail",
      "eInvoiceErrorNotification",
    ];
  }

  state = {
    suggestion: [],
    data: {},
    value: {},
    statusFilter: "all",
    approvalFilter: "all",
    loading: true,
    isFormSubmit: false,
  };

  async componentDidMount() {
    try {
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
        }
      );

      this.setState(
        {
          loading: true,
          data: await services.getNotificationsEmailContent(),
        },
        () => {
          this.formik.resetForm();
          this.setState({ loading: false });
        }
      );
    } catch (e) {
      console.error("error", e);
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  async handleSubmit(values, { setSubmitting }) {
    try {
      if (!this.formik.isSubmitting) return;
      setSubmitting(true);
      this.setState({ loading: true });

      const payload = {
        ...values,
      };

      let response = await services.saveNotificationsEmailContent(payload);
      if (response?.statusCode === 200) {
        NotificationManager.success(
          this.props.intl.formatMessage({
            id: "global.operationSuccessful",
            defaultMessage: "Operation Successful",
          }),
          this.props.intl.formatMessage({
            id: "global.success",
            defaultMessage: "Success",
          })
        );
      }

      this.setState({ loading: false, isFormSubmit: true });
    } catch (e) {
      console.error("error", e);
      this.setState({ loading: false, isFormSubmit: false });
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
    }
  }

  render() {
    const { intl } = this.props;
    const initialValues = {};
    const validations = {};
    this.emailNotificationsTypes.forEach((type) => {
      initialValues[notificationsData[type].fieldEmailEng] =
        this.state.data[notificationsData[type].fieldEmailEng];

      initialValues[notificationsData[type].fieldEmailAr] =
        this.state.data[notificationsData[type].fieldEmailAr];

      initialValues[notificationsData[type].subjectEn] =
        this.state.data[notificationsData[type].subjectEn];
      initialValues[notificationsData[type].emailsTo] =
        this.state.data[notificationsData[type].emailsTo];
      initialValues[notificationsData[type].emailsCC] =
        this.state.data[notificationsData[type].emailsCC];

      validations[notificationsData[type].subjectEn] = Yup.string()
        .label(
          intl.formatMessage({
            id: "global.required",
            defaultMessage: "Email Subject (English)",
          })
        )
        .required();
      validations[notificationsData[type].emailsTo] = Yup.array()
        .of(
          Yup.string()
            .label(
              intl.formatMessage({
                id: "scheduleEmail.invalidEmail",
                defaultMessage: "To",
              })
            )
            .email()
        )
        .min(1, "At least one email is required");
      validations[notificationsData[type].emailsCC] = Yup.array()
        .of(
          Yup.string()
            .label(
              intl.formatMessage({
                id: "scheduleEmail.invalidEmail",
                defaultMessage: "CC",
              })
            )
            .email()
        )
        .min(1, "At least one email is required");
    });

    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape(validations)}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {(formikProps) => {
            const { values, setFieldValue, isSubmitting } = formikProps;
            return (
              <Layout
                noLayout={this.props.noLayout}
                loading={this.state.loading}
              >
                <HeaderTitle
                  id="notification.notificationEmail"
                  defaultMessage="Email Notifications Content"
                />

                <div className="dashboard-table-container card-layout">
                  <div className="panel-primary">
                    <Form>
                      <div className="dashboard-row row">
                        {this.emailNotificationsTypes.map((type) => {
                          return (
                            <EmailContent
                              key={type}
                              type={type}
                              values={values}
                              formikProps={formikProps}
                              setFieldValue={setFieldValue}
                              formatMessage={this.props.intl.formatMessage}
                              emails={[]}
                            />
                          );
                        })}
                      </div>
                      <div className="dashboard-row -nobottom">
                        {this.props.authContext.authorized && (
                          <input
                            type="submit"
                            className="btn btn-primary"
                            value={intl.formatMessage({
                              id: "global.submit",
                              defaultMessage: "Submit",
                            })}
                            disabled={isSubmitting}
                          />
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              </Layout>
            );
          }}
        </Formik>
      </Auth>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(EmailNotifications)));
