import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import Moment from "../shared/LocalizedMoment";

export default class CommentList extends Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={() => {
          this.props.onCancel && this.props.onCancel();
        }}
        centered
      >
        <Modal.Header closeButton={true}>
          <h4>
            <FormattedMessage id="global.comments" defaultMessage="Comments" />
          </h4>
        </Modal.Header>
        <Modal.Body>
          {(!this.props.comments || this.props.comments.length === 0) && (
            <p>
              <FormattedMessage
                id="global.noCommentsAvailable"
                defaultMessage="No comments available"
              />
            </p>
          )}
          {this.props.comments &&
            this.props.comments.map(c => {
              return (
                <div className="card-layout" style={{ marginTop: "20px" }}>
                  <p>{c.body}</p>
                  <div
                    style={{
                      fontSize: ".8em",
                      opacity: 0.6,
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <strong>{c.name}</strong>{" "}
                    <i title={c.createdTime}>
                      <Moment format="lll">{c.createdTime}</Moment>
                    </i>
                  </div>
                </div>
              );
            })}
        </Modal.Body>
      </Modal>
    );
  }
}
