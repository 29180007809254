import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { Yup } from "../../util";
import Layout from "../shared/Layout";
import services from "../../services";
import _ from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import { Button } from "react-bootstrap";
export class ContactusForm extends Component {
  constructor() {
    super();
    this.inquiryKey = {
      "/customer-care": "care",
      "/marketing": "marketing",
      "/career": "career",
      "/service-signup": "service-signup",
    };
  }

  state = {
    data: {},
    loading: true,
  };

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const contactDetails = await services.getContactusById(
        this.props.match.params.id,
        { progressBar: true }
      );
      const state = { data: contactDetails?.response };
      if (this.state.data.carDetails)
        state.carData = JSON.parse(this.state.data.carDetails);
      this.setState(state);
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  _renderBody(formikProps) {
    const { values } = formikProps;
    return (
      <Layout noLayout={this.props.noLayout} loading={this.state.loading}>
        <HeaderTitle
          id="contactus.contactus"
          defaultMessage="Customer Queries"
        />
        <div className="dashboard-table-container card-layout">
          <div className="panel-primary">
            <header className="panel-title">
              <FormattedMessage
                id={"contactus." + this.inquiryKey[this.props.location.state.backUrl]}
                defaultMessage="Inquiry Details"
              />
            </header>

            <Form>
              <div className="dashboard-row">
                <FieldRow
                  type="text"
                  name="userName"
                  labelId="contactus.name"
                  defaultLabel="Name"
                  placeholderId="contactus.name"
                  defaultPlaceholder="Enter name"
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="email"
                  labelId="contactus.email"
                  defaultLabel="Email"
                  placeholderId="contactus.email"
                  defaultPlaceholder="Enter email"
                  formikProps={formikProps}
                  disabled={true}
                />
                <FieldRow
                  type="text"
                  name="phoneNumber"
                  labelId="contactus.phoneNumber"
                  defaultLabel="Phone Number"
                  placeholderId="contactus.phoneNumber"
                  defaultPlaceholder="phone Number"
                  formikProps={formikProps}
                  disabled={true}
                />
                {values.companyName && (
                  <FieldRow
                    type="text"
                    name="companyName"
                    labelId="contactus.companyName"
                    defaultLabel="Company Name"
                    placeholderId="contactus.companyName"
                    defaultPlaceholder="Company Name"
                    formikProps={formikProps}
                    disabled={true}
                  />
                )}
                {values.message && (
                  <FieldRow
                    name="message"
                    type="textarea"
                    labelId="contactus.message"
                    defaultLabel="Message"
                    placeholderId="contactus.message"
                    defaultPlaceholder="Enter message"
                    formikProps={formikProps}
                    disabled={true}
                  />
                )}

                {values.service_en && (
                  <>
                    <FieldRow
                      type="text"
                      name="service_en"
                      labelId="contactus.service"
                      defaultLabel="VIP/Loyalty Service (English)"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="text"
                      name="service_ar"
                      labelId="contactus.service"
                      defaultLabel="VIP/Loyalty Service (Arabic)"
                      formikProps={formikProps}
                      disabled={true}
                    />
                  </>
                )}

                {this.state.data?.car && (
                  <>
                    <FieldRow
                      type="text"
                      name="brand_en"
                      labelId="contactus.brand_en"
                      defaultLabel="Car Brand (English)"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="text"
                      name="brand_ar"
                      labelId="contactus.brand_ar"
                      defaultLabel="Car Brand (Arabic)"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="text"
                      name="model_en"
                      labelId="contactus.model_en"
                      defaultLabel="Car Model (English)"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="text"
                      name="model_ar"
                      labelId="contactus.model_ar"
                      defaultLabel="Car Model (Arabic)"
                      formikProps={formikProps}
                      disabled={true}
                    />

                    {values.brand_en === "OTHER" && (
                      <FieldRow
                        type="text"
                        name="otherBrand"
                        defaultLabel="Other Brand"
                        labelId="contactus.otherBrand"
                        formikProps={formikProps}
                        disabled={true}
                      />
                    )}
                    {(values.brand_en === "OTHER" ||
                      values.model_en === "OTHER") && (
                      <FieldRow
                        type="text"
                        name="otherModel"
                        defaultLabel="Other Model"
                        labelId="contactus.otherModel"
                        formikProps={formikProps}
                        disabled={true}
                      />
                    )}

                    <FieldRow
                      type="text"
                      name="modelYear"
                      labelId="contactus.modelYear"
                      defaultLabel="Car Model Year"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="text"
                      name="plateNumber"
                      labelId="contactus.plateNumber"
                      defaultLabel="Car Plate Number"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="text"
                      name="plateChar"
                      labelId="contactus.plateChar"
                      defaultLabel="Car Plate Char"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="checkbox"
                      name="allowContact"
                      labelId="contactus.allowContact"
                      defaultLabel="Allow Contact"
                      formikProps={formikProps}
                      disabled={true}
                    />
                  </>
                )}

                {this.state.data?.attachment && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={async () => {
                      this.setState({ loading: true });
                      await services.downloadFile(this.state.data?.attachment);
                      this.setState({ loading: false });
                    }}
                  >
                    <FormattedMessage
                      id="contactus.downloadBtn"
                      defaultMessage="Download Attachment"
                    />
                  </button>
                )}
              </div>
              <div className="dashboard-row -nobottom">
                <Button
                  variant="link"
                  onClick={() =>
                    this.props.navigate(this.props.location.state.backUrl)
                  }
                >
                  {this.props.intl.formatMessage({
                    id: "global.back",
                    defaultMessage: "Back",
                  })}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Layout>
    );
  }

  render() {
    const { intl } = this.props;
    const data = this.state.data;

    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Formik
          enableReinitialize
          initialValues={{
            userName: data.userName || "",
            email: data.email || "",
            phoneNumber: data.phoneNumber || "",
            companyName: data.companyName || "",
            message: data.message || "",
            service_en: data.service?.name?.value_en,
            service_ar: data.service?.name?.value_ar,
            brand_en: data.car?.brand?.value_en,
            brand_ar: data.car?.brand?.value_ar,
            model_en: data.car?.product?.value_en,
            model_ar: data.car?.product?.value_ar,
            otherBrand: data?.otherBrand,
            otherModel: data?.otherModel,
            modelYear: data.car?.modelYear,
            plateNumber: data?.plateNumber,
            plateChar: data?.plateChar,
            allowContact: data?.allowContact,
          }}
          validationSchema={Yup.object().shape({
            userName: Yup.string()
              .label(
                intl.formatMessage({
                  id: "contactus.nameInput",
                  defaultMessage: "Name",
                })
              )
              .required(),
            email: Yup.string()
              .label(
                intl.formatMessage({
                  id: "contactus.emailInput",
                  defaultMessage: "Email",
                })
              )
              .required(),
            message: Yup.string()
              .label(
                intl.formatMessage({
                  id: "contactus.messageInput",
                  defaultMessage: "Message",
                })
              )
              .required(),
          })}
        >
          {this._renderBody.bind(this)}
        </Formik>
      </Auth>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(ContactusForm)));
