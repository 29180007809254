import enums from "./enums";
import moment from "moment";

export const getNormalSlots = () => {
  return {
    allSlots: Object.values(enums.HOURLY_SLOTS),
    defaultSlots: Object.values(enums.NINE_HOURS_SLOTS),
  };
};

export const hourlySlots = Object.values(enums.HOURLY_SLOTS);

export const week = Object.values(enums.WEEK);
export const cities = Object.values(enums.CITIES);
export const cityNames = enums.CITY_NAMES;
export const weekDays = week.slice(0, 5);
export const weekends = week.slice(5);
export const combinedDays = Object.keys(enums.COMBINED_DAYS);
export const carModelYears = Object.values(enums.CAR_MODEL_YEARS);
// export const sources  =  Object.values(localStorage.getItem("language") === "en" ? enums.SOURCES : enums.SOURCES_AR)
export const sources = Object.values(enums.SOURCES);
export const sources_ar = Object.values(enums.SOURCES_AR);
export const cnd_order_status = enums.CND_ORDER_STATUS;
export const getQueryData = (key) =>
  new URL(window.location.href).searchParams.get(key);

export const getFormatedDate = (date, format) => {
  let selectedDate = moment(new Date(date)).format(format);
  return selectedDate == "Invalid date" ? format : selectedDate;
};
export const convertCamelCaseToTitleCase = (str) => {
  str = str && str?.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2")?.trim();
  let errorMessage = str?.charAt(0)?.toUpperCase() + str?.slice(1);
  return errorMessage;
};
export const notifications = [
  "scheduleNotification",
  "rescheduleNotification",
  "cancelNotification",
  "createEstimationNotification",
  // "updateEstimationNotification",
];
export const isLtr = () => {
  const isEnglish = localStorage.getItem("language");
  return isEnglish === "en" || isEnglish === "en-US" || isEnglish === "en-GB";
};

export const blobToFile = (blob, fileName, mimeType) => {
  return new File([blob], fileName, {
    type: mimeType,
  });
};
export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  }
  return str;
};

export const isJsonParsable = (obj) => {
  try {
    return JSON.parse(obj);
  } catch (error) {
    return obj;
  }
};
export const automateOTPFields = (e, targetDigit, setShowError) => {
  let value = e.target.value;

  if (setShowError) {
    setShowError(false);

    if (!value?.length) return;

    value = Math.max(0, parseInt(value)).toString().slice(0, 1);

    e.target.value = value;

    if (targetDigit === null) return;
    targetDigit.current.focus();
  } else {
    if ((e?.keyCode || e?.charCode) === 8) {
      !value.length && targetDigit.current.focus();
    }
  }
};

export const getFormattedCarNumberPlate = (licencePlate) =>
  licencePlate?.slice(0, 4) + "-" + licencePlate?.slice(4).toUpperCase();

export const estimationStatus = {
  "Estimation Requested": 0,
  "Estimation In Progress": 1,
  "Estimation Completed": 3,
  "Estimation Closed": 3,
  "Estimation Closed": 3,
};

export const quotationStatus = {
  Requested: 0,
  "In Progress": 1,
  Completed: 3,
};

export const repairStatus = Object.freeze({
  appointments: {
    awaiting_approval: 0,
    repair_in_progress: 1,
    quality_check: 2,
    repair_completed: 4,
  },
  cnd_appointments: {
    notStarted: 0,
    pickedFromCustomer: 1,
    receivedAtCenter: 2,
    pickedFromCenter: 3,
    deliveredToCustomer: 4,
  },
});

export const repairStatusNames = Object.freeze({
  appointments: [
    "Awaiting Approval",
    "Repair In Progress",
    "Quality Check In Progress",
    "Repair Completed",
  ],
  cnd_appointments: [
    "Picked up from Customer",
    "Received at Center",
    "Picked up from Center",
    "Delivered to Customer",
  ],
});

export const isValidObjectId = (id) => {
  const objectIdRegex = /^[0-9a-fA-F]{24}$/;
  return objectIdRegex.test(id);
};

export const offerTypes = [
  {
    label_en: "Non-offer (Unpaid Service)",
    label_ar: "غير العرض (خدمة غير مدفوعة الأجر)",
    value: "nonOffer",
  },
  {
    label_en: "Special Offer",
    label_ar: "عرض خاص",
    value: "specialOffer",
  },
  {
    label_en: "Everyday Deal",
    label_ar: "الصفقة اليومية",
    value: "everydayDeal",
  },
];
export const sessionErrors = [
  "error.sessionExpired",
  "permissions.invalidated",
];
