import BaseService from "./baseService";
import axios from "axios";
import moment from "moment";

class AppointmentService extends BaseService {
  constructor() {
    super();
  }

  async getBrands() {
    const response = await axios.get("/api/brand");
    return response.data;
  }

  async getModels(brand) {
    const response = await axios.get("/api/model", {
      params: {
        brand: brand,
      },
    });
    return response.data;
  }

  async getCarYears(brand, car) {
    const response = await axios.get("/api/year", {
      params: {
        product: car,
        brand,
      },
    });
    return response.data;
  }

  async getProviders() {
    const result = await axios.get("/api/inspirage/branches");
    return result.data.response;
  }

  async getSlots(branchId, serviceId, day) {
    let currentDay = new Date();
    currentDay = currentDay.toISOString();
    const currentTime = moment(currentDay).format("hh:mma");
    const result = await axios.get(
      "/api/branches/get-slots-by-date" +
        `?branchId=${branchId}&serviceId=${serviceId}&date=${day}&currentDay=${currentDay}&currentTime=${currentTime}`
    );
    return result.data.response;
  }

  async registerUser(title, firstName, lastName, phoneNumber, email, source) {
    const request = {
      title,
      firstName,
      lastName,
      phoneNumber,
      email,
      source: !source
        ? "Autohub call center"
        : source === "CarHub"
        ? "Carhub (Autohub Call center)"
        : source,

      isComingFromCms: true,
    };
    const response = await axios.post("/api/user-register", request);
    return response.data;
  }

  async scheduleAppointment({
    title,
    firstName,
    lastName,
    phoneNumber,
    email,
    carNumberPlate,
    brand,
    model,
    otherBrand,
    otherModel,
    carNickName,
    year,
    city,
    branchId,
    services,
    images,
    imageNote,
    date,
    language,
    carOwnBy,
    bookingAppointmentFor,
    acknowledged = true,
    source,
  }) {
    const userData = await this.registerUser(
      title,
      firstName,
      lastName,
      phoneNumber,
      email,
      source
    );

    const formData = new FormData();
    formData.append("userId", userData.id);
    formData.append("title", title);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("phoneNumber", userData.mobilePhone);
    formData.append("email", email);
    formData.append("carNumberPlate", carNumberPlate);
    formData.append("brand", brand);
    formData.append("model", model);
    formData.append("otherBrand", otherBrand);
    formData.append("otherModel", otherModel);
    formData.append("carNickName", carNickName);
    formData.append("year", year);
    formData.append("city", city);
    formData.append("branchId", branchId);
    formData.append("serviceProvider", branchId);
    formData.append("language", language);
    formData.append("carOwnBy", carOwnBy);
    formData.append("bookingAppointmentFor", bookingAppointmentFor);
    formData.append("acknowledged", acknowledged);
    formData.append("isCreatedFromCms", true);
    services.forEach((service) =>
      formData.append("services[]", JSON.stringify(service))
    );
    images && images.forEach((image) => formData.append("images[]", image));
    imageNote && formData.append("imageNote", imageNote);
    formData.append("date", date);
    formData.append("source", source);

    const response = await axios.post("/api/schedule-appointment", formData);
    return response && response.data;
  }

  async rescheduleAppointment(
    { title, firstName, lastName, phoneNumber, date, services, language },
    appointmentId
  ) {
    const userData = await this.registerUser(
      title,
      firstName,
      lastName,
      phoneNumber
    );

    const response = await axios.put("/api/re-schedule-appointment", {
      date: date,
      userId: userData.id,
      services,
      services,
      appointmentId: appointmentId,
      language,
      phoneNumber,
    });
    return response && response.data;
  }

  async getAppointment(appointmentId) {
    const result = await axios.get(
      "/api/schedule-appointment/" + `${appointmentId}`
    );
    return result?.data?.response;
  }
  async deleteOrderAndAppointment({ orderId, appointmentId }) {
    const result = await axios.delete("/api/appointment/delete", {
      params: {
        orderId,
        appointmentId,
      },
    });
    return result?.data?.response;
  }

  async getMonthlyAppointments(options) {
    const result = await axios.post("/api/appointments/date-range", options);
    return result?.data?.response;
  }

  async getCarOwnBy(id) {
    const result = await axios.get("/api/car-own-by/service", {
      params: {
        id,
      },
    });
    return result?.data?.response;
  }

  async getBookingAppointmentFor(id, serviceID) {
    const result = await axios.get("/api/web/booking-appointment-for", {
      params: {
        id,
        serviceID,
      },
    });
    return result?.data?.response;
  }

  async confirmExpressAppointment(id) {
    const response = await axios.put("/api/confirm-express-appointment", {
      id,
    });
    return response && response.data;
  }
  async deleteOrderAndAppointment({ orderId, appointmentId }) {
    const result = await axios.delete("/api/appointment/delete", {
      params: {
        orderId,
        appointmentId,
      },
    });
    return result?.data?.response;
  }
}

export default new AppointmentService();
