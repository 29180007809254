import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import Layout from "../shared/Layout";
import services from "../../services";
import _ from "lodash";
import Auth from "../shared/Auth";
import FieldRow from "../shared/FieldRow";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import { withAuthContext } from "../../state/AuthContext";
import QuotationService from "../../services/quotationService";
import { Yup } from "../../util";
import { NotificationManager } from "react-notifications";
import { Button } from "react-bootstrap";

const QuoteServiceForm = (props) => {
  const quotationId = props.match.params.id;
  const lang = appState.state.language;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const isCompleted = data.status === "Completed";

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await QuotationService.getRequestQuoteById(quotationId);
      setData(data || {});
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const handleApproval = (e, setFieldValue) => {
    const checked = e.target.checked;
    setFieldValue("isApproved", checked);
    setFieldValue("cost", "");
    setFieldValue("costPdf", "");
  };

  const viewFile = async (pdf) => {
    const file = await services.downloadFile(pdf, "view");
    const url = URL.createObjectURL(file);
    window.open(url);
  };

  const dataMapping = (answers) => {
    if (Array.isArray(answers)) {
      return (
        <ul>
          {answers.map((answer) => {
            return <li>{answer[lang]}</li>;
          })}
        </ul>
      );
    } else if (typeof answers === "object") {
      return <span>{answers[lang]}</span>;
    } else if (typeof answers === "string") {
      return <span>{answers}</span>;
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      setLoading(true);

      const payload = {
        id: quotationId,
        ...values,
      };
      await QuotationService.updateQuotationRequest(payload);

      NotificationManager.success(
        props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
      props.navigate("/quotation-requests", {
        isSubmitted: true,
      });
    } catch (e) {
      NotificationManager.error(
        props.intl.formatMessage({
          id: _.get(e, "response.data.message", "error.unexpectedError"),
          defaultMessage: e?.response?.data?.message,
        }),
        props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <Layout noLayout={props.noLayout} loading={loading}>
      <HeaderTitle
        id="menu.quoteRequestsTitle"
        defaultMessage="Quotation Requests"
      />

      <div className="dashboard-table-container card-layout">
        <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
          <Formik
            enableReinitialize
            initialValues={{
              serviceName: data.serviceName?.["value_" + lang],
              title: data.title,
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              phoneNumber: data.phoneNumber,
              make: data.carId?.brand?.["value_" + lang],
              model: data.carId?.product?.["value_" + lang],
              year: data.year,
              otherBrand: data.otherBrand,
              otherModel: data.otherModel,
              carNickName: data.carNickName,
              plateNumber: data.licencePlate?.substring(0, 4),
              plateChar: data.licencePlate?.substring(4),
              city: data.branchId?.city,
              branch: data.branchId?.name["value_" + lang],
              isApproved: data.isApproved || false,
              cost: data.cost,
              costPdf: data.costPdf,
              quoteIdHash: data?.quoteIdHash || "",
              language: data?.language || "en",
            }}
            validationSchema={Yup.object().shape({
              cost: Yup.string().required("Cost is required"),
              costPdf: Yup.string().required("PDF is required"),
            })}
            onSubmit={handleSubmit}
          >
            {(formikProps) => (
              <div className="panel-primary">
                <div>
                  <Form>
                    <div className="dashboard-row">
                      <FieldRow
                        type="text"
                        name="quoteIdHash"
                        labelId="quoteService.quoteIdHash"
                        defaultLabel="Quote Id Hash"
                        placeholderId="quoteService.quoteIdHash"
                        defaultPlaceholder="Quote Id Hash"
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        type="text"
                        name="serviceName"
                        labelId="quoteService.quoteServiceName"
                        defaultLabel="Quote Service Name"
                        placeholderId="quoteService.quoteServiceName"
                        defaultPlaceholder="Quote Service Name"
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        type="text"
                        name="title"
                        disabled={true}
                        labelId="estimations.title"
                        defaultLabel="Title"
                        placeholderId="estimations.enterTitle"
                        defaultPlaceholder="Enter title"
                        formikProps={formikProps}
                        showRequiredFlag={true}
                      />
                      <FieldRow
                        type="text"
                        name="firstName"
                        disabled={true}
                        labelId="estimations.firstName"
                        defaultLabel="First Name"
                        placeholderId="estimations.enterFirstName"
                        defaultPlaceholder="Enter first name"
                        formikProps={formikProps}
                        showRequiredFlag={true}
                      />
                      <FieldRow
                        name="lastName"
                        type={"text"}
                        disabled={true}
                        labelId="estimations.lastName"
                        defaultLabel="Last Name"
                        placeholderId="estimations.enterLastName"
                        defaultPlaceholder="Enter last name"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                      />
                      <FieldRow
                        name="email"
                        type={"text"}
                        disabled={true}
                        labelId="estimations.email"
                        defaultLabel="Email"
                        placeholderId="estimations.enterEmail"
                        defaultPlaceholder="Enter email"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                      />
                      <FieldRow
                        type="text"
                        name="phoneNumber"
                        labelId="estimations.phoneNumber"
                        defaultLabel="Phone Number"
                        placeholderId="estimations.enterPhoneNumber"
                        defaultPlaceholder="Enter phone number"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        type="text"
                        name="make"
                        labelId="estimations.make"
                        defaultLabel="Make"
                        placeholderId="estimations.enterMake"
                        defaultPlaceholder="Car Brand"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        name="model"
                        type="text"
                        labelId="estimations.model"
                        defaultLabel="Model"
                        placeholderId="estimations.enterModel"
                        defaultPlaceholder="Car Model"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                        disabled={true}
                      />
                      {formikProps.values?.otherBrand &&
                      formikProps.values?.otherModel ? (
                        <>
                          <FieldRow
                            type="text"
                            name="otherBrand"
                            labelId="estimations.otherBrand"
                            defaultLabel="Other Brand"
                            placeholderId="estimations.otherBrand"
                            defaultPlaceholder="Enter other brand"
                            showRequiredFlag={true}
                            formikProps={formikProps}
                            disabled={true}
                          />
                          <FieldRow
                            type="text"
                            name="otherModel"
                            labelId="estimations.otherModel"
                            defaultLabel="Other Model"
                            placeholderId="estimations.otherModel"
                            defaultPlaceholder="Enter other model"
                            showRequiredFlag={true}
                            formikProps={formikProps}
                            disabled={true}
                          />
                        </>
                      ) : null}
                      {formikProps.values?.otherModel &&
                      !formikProps.values?.otherBrand ? (
                        <>
                          <FieldRow
                            type="text"
                            name="otherModel"
                            labelId="estimations.otherModel"
                            defaultLabel="Other Model"
                            placeholderId="estimations.otherModel"
                            defaultPlaceholder="Enter other model"
                            showRequiredFlag={true}
                            formikProps={formikProps}
                            disabled={true}
                          />
                        </>
                      ) : null}
                      <FieldRow
                        type="text"
                        name="carNickName"
                        defaultLabel="Car Nick Name"
                        labelId="appointmentCreate.carNickName"
                        placeholderId="appointmentCreate.carNickName"
                        defaultPlaceholder="Enter car nick name"
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        name="year"
                        type="text"
                        labelId="estimations.year"
                        defaultLabel="Year"
                        placeholderId="estimations.year"
                        defaultPlaceholder="Enter year of your product"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <div className="two-fields">
                        <FieldRow
                          type="number"
                          name="plateNumber"
                          defaultLabel="Car Plate Number"
                          labelId="estimations.licencePlate"
                          defaultPlaceholder="5361"
                          placeholderId="estimations.licencePlate"
                          pattern="[0-9]*"
                          showRequiredFlag={true}
                          formikProps={formikProps}
                          colSize={4}
                          disabled={true}
                        />
                        <FieldRow
                          type="text"
                          name="plateChar"
                          defaultPlaceholder="S D F / ق د و"
                          placeholderId="estimations.licencePlate"
                          formikProps={formikProps}
                          disabled={true}
                        />
                      </div>
                      <FieldRow
                        type="text"
                        name="city"
                        labelId="quoteService.cityName"
                        defaultLabel="City Name"
                        placeholderId="quoteService.cityName"
                        defaultPlaceholder="City Name"
                        formikProps={formikProps}
                        disabled={true}
                      />
                      <FieldRow
                        type="text"
                        name="branch"
                        labelId="quoteService.branchName"
                        defaultLabel="Branch Name"
                        placeholderId="quoteService.branchName"
                        defaultPlaceholder="Branch Name"
                        formikProps={formikProps}
                        disabled={true}
                      />

                      <div className="quotation-answers">
                        <h4>
                          <FormattedMessage
                            id="estimation.answersForQues"
                            defaultMessage="Answers to the questions asked:"
                          />
                        </h4>
                        {data.quoteDetails?.map((obj, i) => {
                          return (
                            <div key={i}>
                              <label className="form-label caps block">
                                <b>{obj?.question?.[lang]}</b>
                              </label>
                              <span>{dataMapping(obj.answer)}</span>
                            </div>
                          );
                        })}
                      </div>

                      <div>
                        <h4 style={{ marginBottom: "10px" }}>
                          <FormattedMessage
                            id="quote.forAdmin"
                            defaultMessage="For Admin"
                          />
                        </h4>
                        <FieldRow
                          name="isApproved"
                          type="checkbox"
                          labelId="quote.isApproved"
                          defaultLabel="Approve Quotation"
                          formikProps={formikProps}
                          onChange={(e) =>
                            handleApproval(e, formikProps.setFieldValue)
                          }
                          disabled={isCompleted}
                        />
                        {formikProps.values?.isApproved && (
                          <>
                            <FieldRow
                              type="number"
                              name="cost"
                              labelId="quote.cost"
                              defaultLabel="Cost (SAR)"
                              placeholderId="quote.cost"
                              defaultPlaceholder="Enter Cost (SAR)"
                              showRequiredFlag={true}
                              formikProps={formikProps}
                              disabled={isCompleted}
                            />
                            {!isCompleted ? (
                              <FieldRow
                                type="file"
                                name="costPdf"
                                labelId="quote.uploadPDF"
                                defaultLabel="PDF"
                                placeholderId="quote.uploadPDF"
                                defaultPlaceholder="Quotation PDF"
                                showRequiredFlag={true}
                                formikProps={formikProps}
                                legalDocumentation={true}
                                fileicon={false}
                                allowFiles="application/pdf"
                                disabled={isCompleted}
                              />
                            ) : (
                              <div className="row">
                                <div className="col-md-2 col-sm-2 col-xs-12">
                                  <label className="form-label caps block require-flag">
                                    <FormattedMessage
                                      id="quote.uploadPDF"
                                      defaultMessage="Quotation PDF"
                                    />
                                  </label>
                                </div>
                                <div
                                  className="file-name-display col-md-8 col-sm-8 col-xs-12"
                                  onClick={() =>
                                    viewFile(formikProps.values.costPdf)
                                  }
                                >
                                  <span>
                                    {
                                      formikProps.values.costPdf.split(
                                        "75973"
                                      )[1]
                                    }
                                  </span>
                                  <i className="fa fa-eye" />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="dashboard-row -nobottom">
                      {props.authContext.authorized && !isCompleted && (
                        <input
                          type="submit"
                          className="btn btn-primary"
                          value={props.intl.formatMessage({
                            id: "global.submit",
                            defaultMessage: "Submit",
                          })}
                          disabled={
                            formikProps.isSubmitting ||
                            !formikProps.dirty
                          }
                        />
                      )}
                      <Button
                        variant="link"
                        onClick={() => props.navigate("/quotation-requests")}
                        disabled={formikProps.isSubmitting}
                      >
                        {props.intl.formatMessage({
                          id: "global.cancel",
                          defaultMessage: "Cancel",
                        })}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            )}
          </Formik>
        </Auth>
      </div>
    </Layout>
  );
};

export default withAuthContext(withRouter(injectIntl(QuoteServiceForm)));
