import React, { Component, createRef } from "react";
import { injectIntl } from "react-intl";
import { Form, Formik } from "formik";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import { Yup } from "../../../util";
import appState from "../../../state/AppStateContainer";
import withRouter from "../../../util/withRouter";
import Layout from "../../shared/Layout";
import Auth from "../../shared/Auth";
import NotificationType from "./components/notificationType";
import services from "../../../services";
import HeaderTitle from "../../shared/HeaderTitle";
export class Notifications extends Component {
  constructor() {
    super();
    this.formik = createRef();
  }

  state = {
    data: {},
    loading: true,
  };

  async componentDidMount() {
    try {
      this.subscription = appState.emitter.addListener(
        "onLanguageChange",
        () => {
          this.formik.validateForm();
        }
      );

      const data = await services.getNotificationsContent();
      this.setState({ loading: true, data });
    } catch (e) {
      console.error("error", e);
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  async handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    this.setState({ loading: true });
    try {
      let response = await services.saveNotificationsContent(values);
      if (response?.statusCode === 200) {
        NotificationManager.success(
          this.props.intl.formatMessage({
            id: "global.operationSuccessful",
            defaultMessage: "Operation Successful",
          }),
          this.props.intl.formatMessage({
            id: "global.success",
            defaultMessage: "Success",
          })
        );
      }
    } catch (e) {
      console.error("error", e);
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      setSubmitting(false);
      this.setState({ loading: false });
    }
  }

  render() {
    const { intl } = this.props;
    const {
      scheduleNotifySmsEng,
      scheduleNotifySmsAr,
      scheduleNotifyEmailEng,
      scheduleNotifyEmailAr,
      rescheduleNotifySmsEng,
      rescheduleNotifySmsAr,
      rescheduleNotifyEmailEng,
      rescheduleNotifyEmailAr,
      cancelNotifySmsEng,
      cancelNotifySmsAr,
      cancelNotifyEmailEng,
      cancelNotifyEmailAr,
      otherCarNotifyEmailEng,
      otherCarNotifyEmailAr,
      estimationRequestSmsEng,
      estimationRequestSmsAr,
      closeEstimationRequestSmsEng,
      closeEstimationRequestSmsAr,
      estimationAppointmentNotifySmsEng,
      estimationAppointmentNotifySmsAr,
      estimationAppointmentNotifyEmailEng,
      estimationAppointmentNotifyEmailAr,
      estimationApprovalNotifySmsEng,
      estimationApprovalNotifySmsAr,
      estimationApprovalNotifyEmailEng,
      estimationApprovalNotifyEmailAr,
      estimationCommentNotifySmsEng,
      estimationCommentNotifySmsAr,
      estimationCommentNotifyEmailEng,
      estimationCommentNotifyEmailAr,
      expressNotifySmsEng,
      expressNotifySmsAr,
      expressNotifyEmailEng,
      expressNotifyEmailAr,
      paymentAddonNotifySmsEng,
      paymentAddonNotifySmsAr,
      paymentAddonNotifyEmailEng,
      paymentAddonNotifyEmailAr,
      paymentOfferNotifySmsEng,
      paymentOfferNotifySmsAr,
      paymentOfferNotifyEmailEng,
      paymentOfferNotifyEmailAr,
      paymentUpsellNotifySmsEng,
      paymentUpsellNotifySmsAr,
      paymentUpsellNotifyEmailEng,
      paymentUpsellNotifyEmailAr,
      expresscustomerNotificationSmsEn,
      expresscustomerNotificationSmsAr,
      expressCommentEn,
      expressCommentAr,
      expressConfiramtionNotificationSmsEn,
      expressConfiramtionNotificationSmsAr,
      finalInvoiceSmsEn,
      finalInvoiceSmsAr,
      otpNotificationEn,
      otpNotificationAr,
      refundContentSmsEn,
      refundContentSmsAr,
      fastCollectNotifySmsEng,
      fastCollectNotifySmsAr,
      recommendedOfferSmsEn,
      recommendedOfferSmsAr,
      fastCollectSatisfiedSmsEn,
      fastCollectSatisfiedSmsAr,
      fastCollectNotSatisfiedSmsEn,
      fastCollectNotSatisfiedSmsAr,
      fastCollectRepairCompletedSmsEn,
      fastCollectRepairCompletedSmsAr,
      quoteBookAndPaySmsEn,
      quoteBookAndPaySmsAr,
      quoteBookAndPayUserRequestSmsEn,
      quoteBookAndPayUserRequestSmsAr,
      subscriptionBuySmsEn,
      subscriptionBuySmsAr,
      docsVerifiedForCNDSmsEn,
      docsVerifiedForCNDSmsAr,
      carReceivedAtCenterForCNDSmsEn,
      carReceivedAtCenterForCNDSmsAr,
      scheduleDeliveryForCNDSmsEn,
      scheduleDeliveryForCNDSmsAr,
      serviceCompleteForCNDSmsEn,
      serviceCompleteForCNDSmsAr,
      qmsUnservedCustomerSmsEn,
      qmsUnservedCustomerSmsAr,
      activeOtpNotificationSms,
      activeScheduleNotifySms,
      activeRescheduleNotifySms,
      activeCancelNotifySms,
      activeFastCollectSms,
      activeOtherCarNotifySms,
      activeEstimationRequestSms,
      activeCloseEstimationRequestSms,
      activeEstimationAppointmentNotifySms,
      activeEstimationApprovalSms,
      activeEstimationCommentSms,
      activeExpressNotifySms,
      activePaymentAddonNotifySms,
      activePaymentOfferNotifySms,
      activePaymentUpsellNotifySms,
      activeExpressCustomerNotificationSms,
      activeExpressCommentSms,
      activeExpressConfirmationNotificationSms,
      activeFinalInvoiceSms,
      activeRefundContentSms,
      activeRecommendedOfferSms,
      activeFastCollectSatisfiedSms,
      activeFastCollectNotSatisfiedSms,
      activeFastCollectRepairCompletedSms,
      activeQuoteBookAndPaySms,
      activeQuoteBookAndPayUserRequestSms,
      activeSubscriptionBuySms,
      activeDocsVerifiedForCustomerSms,
      activeCarReceivedAtCenterForCndSms,
      activeScheduleDeliveryForCndSms,
      activeServiceCompletedForCndSms,
      activeQmsUnservedCustomerSms,
    } = this.state.data;

    return (
      <Auth requireAuth={true} roles={["sadmin"]}>
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={{
            // Otp
            otpNotificationEn: otpNotificationEn || "",
            otpNotificationAr: otpNotificationAr || "",
            activeOtpNotificationSms: activeOtpNotificationSms || false,

            // Schedule
            scheduleNotifySmsEng: scheduleNotifySmsEng || "",
            scheduleNotifySmsAr: scheduleNotifySmsAr || "",
            scheduleNotifyEmailEng: scheduleNotifyEmailEng || "",
            scheduleNotifyEmailAr: scheduleNotifyEmailAr || "",
            activeScheduleNotifySms: activeScheduleNotifySms || false,

            // Reschedule
            rescheduleNotifySmsEng: rescheduleNotifySmsEng || "",
            rescheduleNotifySmsAr: rescheduleNotifySmsAr || "",
            rescheduleNotifyEmailEng: rescheduleNotifyEmailEng || "",
            rescheduleNotifyEmailAr: rescheduleNotifyEmailAr || "",
            activeRescheduleNotifySms: activeRescheduleNotifySms || false,

            // Cancel
            cancelNotifySmsEng: cancelNotifySmsEng || "",
            cancelNotifySmsAr: cancelNotifySmsAr || "",
            cancelNotifyEmailEng: cancelNotifyEmailEng || "",
            cancelNotifyEmailAr: cancelNotifyEmailAr || "",
            activeCancelNotifySms: activeCancelNotifySms || false,

            // Fast collect
            fastCollectNotifySmsEng: fastCollectNotifySmsEng || "",
            fastCollectNotifySmsAr: fastCollectNotifySmsAr || "",
            activeFastCollectSms: activeFastCollectSms || false,

            // other
            otherCarNotifyEmailEng: otherCarNotifyEmailEng || "",
            otherCarNotifyEmailAr: otherCarNotifyEmailAr || "",
            activeOtherCarNotifySms: activeOtherCarNotifySms || false,

            // estimation request
            estimationRequestSmsEng: estimationRequestSmsEng || "",
            estimationRequestSmsAr: estimationRequestSmsAr || "",
            activeEstimationRequestSms: activeEstimationRequestSms || false,

            // Close estimation request
            closeEstimationRequestSmsEng: closeEstimationRequestSmsEng || "",
            closeEstimationRequestSmsAr: closeEstimationRequestSmsAr || "",
            activeCloseEstimationRequestSms:
              activeCloseEstimationRequestSms || false,

            // Estimation Appointment Schedule
            estimationAppointmentNotifySmsEng:
              estimationAppointmentNotifySmsEng || "",
            estimationAppointmentNotifySmsAr:
              estimationAppointmentNotifySmsAr || "",
            estimationApprovalNotifyEmailEng:
              estimationApprovalNotifyEmailEng || "",
            estimationAppointmentNotifyEmailEng:
              estimationAppointmentNotifyEmailEng || "",
            estimationAppointmentNotifyEmailAr:
              estimationAppointmentNotifyEmailAr || "",
            activeEstimationAppointmentNotifySms:
              activeEstimationAppointmentNotifySms || false,

            // Estimation Approval Content
            estimationApprovalNotifySmsEng:
              estimationApprovalNotifySmsEng || "",
            estimationApprovalNotifySmsAr: estimationApprovalNotifySmsAr || "",
            estimationApprovalNotifyEmailEng:
              estimationApprovalNotifyEmailEng || "",
            estimationApprovalNotifyEmailAr:
              estimationApprovalNotifyEmailAr || "",
            activeEstimationApprovalSms: activeEstimationApprovalSms || false,

            // Estimation Comment Content
            estimationCommentNotifySmsEng: estimationCommentNotifySmsEng || "",
            estimationCommentNotifySmsAr: estimationCommentNotifySmsAr || "",
            estimationCommentNotifyEmailEng:
              estimationCommentNotifyEmailEng || "",
            estimationCommentNotifyEmailAr:
              estimationCommentNotifyEmailAr || "",
            activeEstimationCommentSms: activeEstimationCommentSms || false,

            // express Content
            expressNotifySmsEng: expressNotifySmsEng || "",
            expressNotifySmsAr: expressNotifySmsAr || "",
            expressNotifyEmailEng: expressNotifyEmailEng || "",
            expressNotifyEmailAr: expressNotifyEmailAr || "",
            activeExpressNotifySms: activeExpressNotifySms || false,

            // payment addon Content
            paymentAddonNotifySmsEng: paymentAddonNotifySmsEng || "",
            paymentAddonNotifySmsAr: paymentAddonNotifySmsAr || "",
            paymentAddonNotifyEmailEng: paymentAddonNotifyEmailEng || "",
            paymentAddonNotifyEmailAr: paymentAddonNotifyEmailAr || "",
            activePaymentAddonNotifySms: activePaymentAddonNotifySms || false,

            // payment offer Content
            paymentOfferNotifySmsEng: paymentOfferNotifySmsEng || "",
            paymentOfferNotifySmsAr: paymentOfferNotifySmsAr || "",
            paymentOfferNotifyEmailEng: paymentOfferNotifyEmailEng || "",
            paymentOfferNotifyEmailAr: paymentOfferNotifyEmailAr || "",
            activePaymentOfferNotifySms: activePaymentOfferNotifySms || false,

            // payment upsell Content
            paymentUpsellNotifySmsEng: paymentUpsellNotifySmsEng || "",
            paymentUpsellNotifySmsAr: paymentUpsellNotifySmsAr || "",
            paymentUpsellNotifyEmailEng: paymentUpsellNotifyEmailEng || "",
            paymentUpsellNotifyEmailAr: paymentUpsellNotifyEmailAr || "",
            activePaymentUpsellNotifySms: activePaymentUpsellNotifySms || false,

            // Express
            expresscustomerNotificationSmsEn:
              expresscustomerNotificationSmsEn || "",
            expresscustomerNotificationSmsAr:
              expresscustomerNotificationSmsAr || "",
            activeExpressCustomerNotificationSms:
              activeExpressCustomerNotificationSms || false,

            expressCommentEn: expressCommentEn || "",
            expressCommentAr: expressCommentAr || "",
            activeExpressCommentSms: activeExpressCommentSms || false,

            expressConfiramtionNotificationSmsEn:
              expressConfiramtionNotificationSmsEn || "",
            expressConfiramtionNotificationSmsAr:
              expressConfiramtionNotificationSmsAr || "",
            activeExpressConfirmationNotificationSms:
              activeExpressConfirmationNotificationSms || false,

            // Invoice
            finalInvoiceSmsEn: finalInvoiceSmsEn || "",
            finalInvoiceSmsAr: finalInvoiceSmsAr || "",
            activeFinalInvoiceSms: activeFinalInvoiceSms || false,

            // Refund
            refundContentSmsEn: refundContentSmsEn || "",
            refundContentSmsAr: refundContentSmsAr || "",
            activeRefundContentSms: activeRefundContentSms || false,

            recommendedOfferSmsEn: recommendedOfferSmsEn || "",
            recommendedOfferSmsAr: recommendedOfferSmsAr || "",
            activeRecommendedOfferSms: activeRecommendedOfferSms || false,

            fastCollectSatisfiedSmsEn: fastCollectSatisfiedSmsEn || "",
            fastCollectSatisfiedSmsAr: fastCollectSatisfiedSmsAr || "",
            activeFastCollectSatisfiedSms:
              activeFastCollectSatisfiedSms || false,

            fastCollectNotSatisfiedSmsEn: fastCollectNotSatisfiedSmsEn || "",
            fastCollectNotSatisfiedSmsAr: fastCollectNotSatisfiedSmsAr || "",
            activeFastCollectNotSatisfiedSms:
              activeFastCollectNotSatisfiedSms || false,

            fastCollectRepairCompletedSmsEn:
              fastCollectRepairCompletedSmsEn || "",
            fastCollectRepairCompletedSmsAr:
              fastCollectRepairCompletedSmsAr || "",
            activeFastCollectRepairCompletedSms:
              activeFastCollectRepairCompletedSms || false,

            // Quote Service
            quoteBookAndPaySmsEn: quoteBookAndPaySmsEn || "",
            quoteBookAndPaySmsAr: quoteBookAndPaySmsAr || "",
            activeQuoteBookAndPaySms: activeQuoteBookAndPaySms || false,

            quoteBookAndPayUserRequestSmsEn:
              quoteBookAndPayUserRequestSmsEn || "",
            quoteBookAndPayUserRequestSmsAr:
              quoteBookAndPayUserRequestSmsAr || "",
            activeQuoteBookAndPayUserRequestSms:
              activeQuoteBookAndPayUserRequestSms || false,

            subscriptionBuySmsEn: subscriptionBuySmsEn || "",
            subscriptionBuySmsAr: subscriptionBuySmsAr || "",
            activeSubscriptionBuySms: activeSubscriptionBuySms || false,

            docsVerifiedForCNDSmsEn: docsVerifiedForCNDSmsEn || "",
            docsVerifiedForCNDSmsAr: docsVerifiedForCNDSmsAr || "",
            activeDocsVerifiedForCustomerSms:
              activeDocsVerifiedForCustomerSms || false,

            carReceivedAtCenterForCNDSmsEn:
              carReceivedAtCenterForCNDSmsEn || "",
            carReceivedAtCenterForCNDSmsAr:
              carReceivedAtCenterForCNDSmsAr || "",
            activeCarReceivedAtCenterForCndSms:
              activeCarReceivedAtCenterForCndSms || false,

            scheduleDeliveryForCNDSmsEn: scheduleDeliveryForCNDSmsEn || "",
            scheduleDeliveryForCNDSmsAr: scheduleDeliveryForCNDSmsAr || "",
            activeScheduleDeliveryForCndSms:
              activeScheduleDeliveryForCndSms || false,

            serviceCompleteForCNDSmsEn: serviceCompleteForCNDSmsEn || "",
            serviceCompleteForCNDSmsAr: serviceCompleteForCNDSmsAr || "",
            activeServiceCompletedForCndSms:
              activeServiceCompletedForCndSms || false,

            qmsUnservedCustomerSmsEn: qmsUnservedCustomerSmsEn || "",
            qmsUnservedCustomerSmsAr: qmsUnservedCustomerSmsAr || "",
            activeQmsUnservedCustomerSms: activeQmsUnservedCustomerSms || false,
          }}
          validationSchema={Yup.object().shape({
            otpNotificationEn: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.englishSMS",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            otpNotificationAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.arabicSMS",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
            scheduleNotifySmsEng: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.englishSMS",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            scheduleNotifySmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.arabicSMS",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
            scheduleNotifyEmailEng: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.englishEmail",
                  defaultMessage: "Email in English",
                })
              )
              .trim()
              .required(),
            scheduleNotifyEmailAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.arabicEmail",
                  defaultMessage: "Email in Arabic",
                })
              )
              .trim()
              .required(),

            rescheduleNotifySmsEng: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.englishSMS",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            rescheduleNotifySmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.arabicSMS",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
            rescheduleNotifyEmailEng: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.englishEmail",
                  defaultMessage: "Email in English",
                })
              )
              .trim()
              .required(),
            rescheduleNotifyEmailAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.arabicEmail",
                  defaultMessage: "Email in Arabic",
                })
              )
              .trim()
              .required(),
            cancelNotifySmsEng: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.englishSMS",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            cancelNotifySmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.arabicSMS",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
            cancelNotifyEmailEng: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.englishEmail",
                  defaultMessage: "Email in English",
                })
              )
              .trim()
              .required(),
            cancelNotifyEmailAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.arabicEmail",
                  defaultMessage: "Email in Arabic",
                })
              )
              .trim()
              .required(),
            otherCarNotifyEmailEng: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.englishEmail",
                  defaultMessage: "Email in English",
                })
              )
              .trim()
              .required(),
            otherCarNotifyEmailAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.arabicEmail",
                  defaultMessage: "Email in Arabic",
                })
              )
              .trim()
              .required(),
            estimationRequestSmsEng: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.englishSMS",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            estimationRequestSmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.arabicSMS",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
            closeEstimationRequestSmsEng: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.englishSMS",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            closeEstimationRequestSmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.arabicSMS",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
            estimationAppointmentNotifySmsEng: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.englishSMS",
                  defaultMessage: "SMS in English",
                })
              )
              .trim(),
            estimationAppointmentNotifySmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.arabicSMS",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim(),
            expressCommentEn: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.expressCommentEn",
                  defaultMessage: "Express Comment SMS in English",
                })
              )
              .trim()
              .required(),
            finalInvoiceSmsEn: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.finalInvoiceSmsEn",
                  defaultMessage: "Final Invoice SMS in English",
                })
              )
              .trim()
              .required(),
            finalInvoiceSmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.finalInvoiceSmsAr",
                  defaultMessage: "Final Invoice SMS in Arabic",
                })
              )
              .trim()
              .required(),
            refundContentSmsEn: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.refundContentSmsEn",
                  defaultMessage: "Refund SMS in English",
                })
              )
              .trim()
              .required(),
            refundContentSmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.refundContentSmsAr",
                  defaultMessage: "Refund SMS in Arabic",
                })
              )
              .trim()
              .required(),
            recommendedOfferSmsEn: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.recommendedOfferEn",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            recommendedOfferSmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.recommendedOfferAr",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
            fastCollectSatisfiedSmsEn: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.fastCollectSatisfiedSmsEn",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            fastCollectSatisfiedSmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.fastCollectSatisfiedSmsAr",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
            fastCollectNotSatisfiedSmsEn: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.fastCollectNotSatisfiedSmsEn",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            fastCollectNotSatisfiedSmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.fastCollectNotSatisfiedSmsAr",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),

            fastCollectNotifySmsEng: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.englishSMS",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            fastCollectNotifySmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.arabicSMS",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
            fastCollectRepairCompletedSmsEn: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.fastCollectRepairCompletedSmsEn",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),

            fastCollectRepairCompletedSmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.fastCollectRepairCompletedSmsAr",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
            quoteBookAndPaySmsEn: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.quoteBookAndPaySmsEn",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            quoteBookAndPaySmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.quoteBookAndPaySmsAr",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
            quoteBookAndPayUserRequestSmsEn: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.quoteBookAndPayUserRequestSmsEn",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            quoteBookAndPayUserRequestSmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.quoteBookAndPayUserRequestSmsAr",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
            subscriptionBuySmsEn: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.subscriptionBuySmsEn",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            subscriptionBuySmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.subscriptionBuySmsAr",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
            docsVerifiedForCNDSmsEn: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.docsVerifiedForCNDSmsEn",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            docsVerifiedForCNDSmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.docsVerifiedForCNDSmsAr",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
            carReceivedAtCenterForCNDSmsEn: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.carReceivedAtCenterForCNDSmsEn",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            carReceivedAtCenterForCNDSmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.carReceivedAtCenterForCNDSmsAr",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
            scheduleDeliveryForCNDSmsEn: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.scheduleDeliveryForCNDSmsEn",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            scheduleDeliveryForCNDSmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.scheduleDeliveryForCNDSmsAr",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
            serviceCompleteForCNDSmsEn: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.serviceCompleteForCNDSmsEn",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            serviceCompleteForCNDSmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.serviceCompleteForCNDSmsAr",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
            qmsUnservedCustomerSmsEn: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.qmsUnservedCustomerSmsEn",
                  defaultMessage: "SMS in English",
                })
              )
              .trim()
              .required(),
            qmsUnservedCustomerSmsAr: Yup.string()
              .label(
                intl.formatMessage({
                  id: "translation.qmsUnservedCustomerSmsAr",
                  defaultMessage: "SMS in Arabic",
                })
              )
              .trim()
              .required(),
          })}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {(formikProps) => {
            const { isSubmitting } = formikProps;
            return (
              <Layout loading={this.state.loading}>
                <div className="dashboard-table-container card-layout">
                  <div className="panel-primary">
                    <HeaderTitle
                      id="notify.notificationMsgs"
                      defaultMessage="SMS Notifications Content"
                    />

                    <Form>
                      <div>
                        <NotificationType
                          type="otpNotification"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="scheduleNotification"
                          formikProps={formikProps}
                        />
                        <NotificationType
                          type="otherCarNotification"
                          formikProps={formikProps}
                          renderEmailOnly
                        />
                        <NotificationType
                          type="rescheduleNotification"
                          formikProps={formikProps}
                        />
                        <NotificationType
                          type="cancelNotification"
                          formikProps={formikProps}
                        />
                        <NotificationType
                          type="fastCollect"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="estimationRequest"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="estimationApproval"
                          formikProps={formikProps}
                        />
                        <NotificationType
                          type="estimationComment"
                          formikProps={formikProps}
                        />
                        <NotificationType
                          type="closeEstimationRequest"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="estimationAppointment"
                          formikProps={formikProps}
                        />
                        <NotificationType
                          type="express"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="paymentAddon"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="paymentOffer"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="paymentUpsell"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="expressCustomerNotification"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="expressComment"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="expressConfiramtionNotification"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="finalInvoice"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="refundContent"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="recommendedOfferContent"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="fastCollectSatisfiedSms"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="fastCollectNotSatisfiedSms"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="fastCollectRepairCompletedSms"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="quoteBookAndPay"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="quoteBookAndPayUserRequest"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="subscriptionBuy"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="docsVerifiedForCND"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="carReceivedAtCenterForCND"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="scheduleDeliveryForCND"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="serviceCompleteForCND"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                        <NotificationType
                          type="qmsUnservedCustomers"
                          formikProps={formikProps}
                          renderSmsOnly
                        />
                      </div>

                      <div className="dashboard-row -nobottom">
                        <input
                          type="submit"
                          className="btn btn-primary"
                          value={this.props.intl.formatMessage({
                            id: "global.submit",
                            defaultMessage: "Submit",
                          })}
                          disabled={isSubmitting}
                        />
                      </div>
                    </Form>
                  </div>
                </div>
              </Layout>
            );
          }}
        </Formik>
      </Auth>
    );
  }
}

export default withRouter(injectIntl(Notifications));
