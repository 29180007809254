import React, { Component } from "react";
import appState from "../../state/AppStateContainer";
import withRouter from "../../util/withRouter";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { ImageProvider } from "../shared/ImageProvider";
import Layout from "./Layout";
export class Header extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    showNotification: false,
    data: [],
    name: [],
  };

  getLocalizedName(o) {
    return appState.state.language === "en"
      ? _.get(o, "name.value_en") || _.get(o, "englishName")
      : _.get(o, "name.value_ar") ||
          _.get(o, "arabicName") ||
          _.get(o, "name.value_en") ||
          _.get(o, "englishName");
  }

  handleCloseNotification() {
    this.setState({ showNotification: false });
  }

  async logoutUser() {
    await appState.logout();
  }

  render() {
    return (
      <header>
        <nav className="navbar navbar-default navbar-fixed-top">
          <button
            type="button"
            className="sidebar-toggle navbar-toggle collapsed"
            sidebar-toggle=""
            sidebar-class="sidebar"
            dashboard-class="dashboard-main"
            onClick={() => {
              let currentPos = window.$(".dashboard-main").scrollTop();
              appState.toggleSidebar();
              window.$(".dashboard-main").scrollTop(0);
              setTimeout(() => {
                window.$(".dashboard-main").scrollTop(currentPos);
              }, 500);
            }}
          >
            <span className="sr-only" />
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          <div className="container-fluid">
            {appState.state.title && (
              <div className="navbar-header">
                <span className="page-title title-name">
                  <FormattedMessage
                    id={appState.state.titleId}
                    defaultMessage={appState.state.title}
                  />
                </span>
              </div>
            )}
            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <ul className="nav navbar-nav navbar-right hidden-xs hidden-sm hidden-md">
                {(_.get(appState, "state.token.role") === "spadmin" ||
                  _.get(appState, "state.token.role") === "wsadmin") && (
                  <li>
                    <span className="profile-photo">
                      <a>
                        <ImageProvider imageSrc={this.state.image} />
                      </a>
                    </span>
                  </li>
                )}
                {_.get(appState, "state.token.role") === "sadmin" && (
                  <li>
                    <span className="profile-photo">
                      <a>
                        <img src="/design/images/avatar.png" alt="" />
                      </a>
                    </span>
                  </li>
                )}
                <li>
                  <a
                    className="profile-link"
                    onClick={() => {
                      if (_.get(appState, "state.token.role") === "spadmin") {
                        this.props.navigate(
                          `/shop/${_.get(appState, "state.token.parent._id")}`
                        );
                      } else if (
                        _.get(appState, "state.token.role") === "wsadmin"
                      ) {
                      }
                    }}
                  >
                    {(_.get(appState, "state.token.role") === "spadmin" ||
                      _.get(appState, "state.token.role") === "wsadmin") && (
                      <p className="contact-links">
                        {this.getLocalizedName(this.state)}
                      </p>
                    )}
                    {_.get(appState, "state.token.role") === "sadmin" && (
                      <p className="contact-links">
                        {appState.state.token?.fullName}{" "}
                      </p>
                    )}{" "}
                  </a>
                </li>
                <li>
                  <a
                    className="changeLang-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      appState.changeLanguage();
                    }}
                  >
                    <span className="fa fa-language fa-2x" />
                  </a>
                </li>
                <li>
                  {!this.props.registerSp && (
                    <a
                      className="logout-button"
                      onClick={this.logoutUser.bind(this)}
                    >
                      <span className="fa fa-sign-out fa-2x" />
                    </a>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default withRouter(Header);
