import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import $ from "jquery";
// import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { NotificationContainer } from "react-notifications";
import { Provider, Subscribe } from "unstated";
import { IntlProvider } from "react-intl";
import AuthContext from "./state/AuthContext";
import _ from "lodash";
import appState, { AppStateContainer } from "./state/AppStateContainer";
import TranslationLabel from "./components/shared/TranslationLabel";
import TranslationModal from "./components/shared/TranslationModal";
import Sidebar from "./components/shared/Sidebar";
import Services from "./services";
import { ModulesEnum, ActivitiesEnum } from "./staticData/Activities";

import Login from "./components/login";
import ServiceAdvisorLogin from "./components/serviceAdvisorLogin";
import ForgotPassword from "./components/forgotPassword";
import ForgotPasswordEmailSent from "./components/forgotPassword/emailSent";
import ResetPassword from "./components/resetPassword";
import Dashboard from "./components/dashboard";
import AutoHubLocalization from "./components/LocalizationContent/autoHubLocalization";
import ChangeWebPassword from "./components/credentialManager/ChangeWebPassword";
import ChangeCMSPassword from "./components/credentialManager/ChangeCMSPassword";
import BranchList from "./components/branches/BranchList";
import BranchForm from "./components/branches/BranchForm";
import CareemPromoList from "./components/careemPromo/CareemPromoList";
import CareemPromoForm from "./components/careemPromo/CareemPromoForm";
import CarList from "./components/car/CarList";
import CarForm from "./components/car/CarForm";
import CityList from "./components/cities/CitiesList";
import ContactusList from "./components/contactus/contactusList";
import ContactusForm from "./components/contactus/contactusForm";
import SubscriptionList from "./components/subscription/SubscriptionList";
import SubscriptionForm from "./components/subscription/SubscriptionForm";
import Track from "./components/track/Track";
import TrackFeedbackList from "./components/track/trackFeedback";
import TrackFeedbackForm from "./components/track/trackFeedback/trackFeedbackForm";
import UpsellRecommendations from "./components/track/upsellRecommendations";
import UpsellRecommendationForm from "./components/track/upsellRecommendations/upsellRecommendationForm";
import ActivitList from "./components/activity";
import SubscribedOffersList from "./components/subscribedOffers/SubscribedOffersList";
import SubscribedOffersForm from "./components/subscribedOffers/SubscribedOffersForm";
import CategoryList from "./components/category/CategoryList";
import CategoryForm from "./components/category/CategoryForm";
import ProductList from "./components/product/productList";
import ProductForm from "./components/product/productForm";
import VariantList from "./components/variants/Variants";
import VariantForm from "./components/variants/VariantForm";
import SettingsForm from "./components/Settings";
import ServiceCategoryList from "./components/serviceCategory/ServiceCategoryList";
import ServiceCategoryForm from "./components/serviceCategory/ServiceCategoryForm";
import ServiceList from "./components/service/serviceList";
import ServiceForm from "./components/service/serviceForm";
import ScheduleEmailList from "./components/scheduleEmail/scheduleEmailList";
import ScheduleEmailForm from "./components/scheduleEmail/scheduleForm";
import AppointmentList from "./components/appointment/AppointmentList";
import AppointmentForm from "./components/appointment/appointmentForm";
import RequiredDocumentsList from "./components/requiredDocuments/requiredDocumentsListing";
import RequiredDocumentForm from "./components/requiredDocuments";
import QuoteRequestList from "./components/quoteRequests/quoteRequestList";
import QuoteRequestForm from "./components/quoteRequests/quoteRequestForm";
import PromoList from "./components/promoMangement/PromoList";
import PromoForm from "./components/promoMangement/PromoForm";
import RoleList from "./components/permissions/RoleList";
import RoleForm from "./components/permissions/RoleForm";
import AdminList from "./components/permissions/AdminList";
import AdminForm from "./components/permissions/AdminForm";
import Orders from "./components/order/Orders";
import OrderDetails from "./components/order/OrderDetails";
import OrderReport from "./components/orderReport/OrderReport";
import BlogTagsList from "./components/blogs/BlogTagsList";
import BlogTagsForm from "./components/blogs/BlogTagsForm";
import BlogList from "./components/blogs/BlogList";
import BlogForm from "./components/blogs/BlogForm";
import KaizenList from "./components/kaizen";
import KaizenForm from "./components/kaizen/kaizenForm";
import EstimationRequestList from "./components/estimation/estimationRequestList";
import EstimationRequestForm from "./components/estimation/estimationRequestForm";
import UserList from "./components/user/userList";
import QuotationList from "./components/quotation/QuotationList";
import QuotationForm from "./components/quotation/QuotationForm";
import PagesAndMediaContent from "./components/content/Pages&Media";
import HeadlineList from "./components/content/headlines/headlineList";
import HeadlineForm from "./components/content/headlines/headlineForm";
import homeBannersList from "./components/content/home/homeBannersList";
import HomeBannerForm from "./components/content/home/homeBannerForm";
import SMSNotifications from "./components/content/notifications/SMSNotifications";
import EmailNotifications from "./components/content/notifications/EmailNotifications";

import PermissionsChanged from "./components/errors/PermissionsChanged";
import AccessDenied from "./components/errors/AccessDenied";
import GenericError from "./components/errors/GenericError";
import InternetError from "./components/errors/InternetError";
import SessionExpired from "./components/errors/AuthSessionExpired";
import NotFound from "./components/errors/NotFound";

import "react-notifications/lib/notifications.css";
import "nprogress/nprogress.css";

$(document).on("click", "input[type=submit]", function (e) {
  setTimeout(function () {
    if ($(".text-warning").length > 0) {
      e.preventDefault();
      $("#rightSection").animate(
        {
          scrollTop:
            $("#rightSection").scrollTop() +
            $(".text-warning").first().offset().top -
            150,
        },
        100
      );
    }
  }, 100);
});

const ProtectedRoute = ({
  element: Component,
  moduleId,
  addActivityId,
  updateActivityId,
  activityId,
  ...rest
}) => {
  let authorized = true;
  let isForm = false;

  if (activityId) authorized = Services.canPerformActivity(activityId);
  if (addActivityId || updateActivityId) isForm = true;
  if (moduleId) authorized = Services.hasAccessToModule(moduleId);
  else if (isForm) {
    const { id } = useParams();
    const updating = !!id;

    if (!updating && addActivityId)
      authorized = Services.canPerformActivity(addActivityId);
    else if (updating && updateActivityId)
      authorized = Services.canPerformActivity(updateActivityId);
  }

  const location = useLocation();
  return (
    <AuthContext.Provider value={{ authorized }}>
      {isForm ? (
        <Component {...rest} authorized={authorized} />
      ) : authorized ? (
        <Component {...rest} />
      ) : (
        <Navigate to="/denied" state={{ from: location.pathname }} replace />
      )}
    </AuthContext.Provider>
  );
};

const App = () => {
  useEffect(() => {
    const setupApp = async () => {
      appState.setSidebarActive(true);
      appState.changeLanguageLayout(appState.state.language);

      if (Services.mobileAndTabletcheck()) $("html").addClass("is-mobile");

      const messages = await Services.getTranslations();
      appState.setState({ messages });
    };

    setupApp();
  }, []);

  const renderApp = (appState) => {
    const { token, editingTranslationId, messages } = appState.state;

    const handleTranslationSubmit = async (values) => {
      let { translation } = await Services.updateTranslation(values);
      const updatedMessages = JSON.parse(JSON.stringify(messages));
      updatedMessages.en[translation.stringId] = translation.value.value_en;
      updatedMessages.ar[translation.stringId] = translation.value.value_ar;
      await appState.setState({
        messages: updatedMessages,
        editingTranslationId: null,
        editingTranslationDefault: null,
      });
    };

    return (
      <>
        <section className="body-wrapper" id="content">
          {token && <Sidebar />}

          <Routes>
            <Route
              path="/"
              element={!token ? <Login /> : <Navigate to="/dashboard" />}
            />
            <Route
              path="/service-advisor/login"
              element={!token ? <ServiceAdvisorLogin /> : <Navigate to="/dashboard" />}
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/forgot-password/email-sent"
              element={<ForgotPasswordEmailSent />}
            />
            <Route
              path="/reset-password/:access_token?"
              element={<ResetPassword />}
            />
            <Route
              path="/set-password/:access_token?"
              element={<ResetPassword />}
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute
                  element={Dashboard}
                  moduleId={ModulesEnum.dashboard}
                />
              }
            />
            <Route
              path="/autohub-translation"
              element={<ProtectedRoute element={AutoHubLocalization} />}
            />
            <Route
              path="/branches"
              element={
                <ProtectedRoute
                  element={BranchList}
                  moduleId={ModulesEnum.branches}
                />
              }
            />
            <Route
              path="/branch/:id?"
              element={
                <ProtectedRoute
                  element={BranchForm}
                  addActivityId={ActivitiesEnum.branch__add_branch}
                  updateActivityId={ActivitiesEnum.branch__update_branch}
                />
              }
            />
            <Route
              path="/careem-promos"
              element={
                <ProtectedRoute
                  element={CareemPromoList}
                  moduleId={ModulesEnum.careem_promo}
                />
              }
            />
            <Route
              path="/careem-promo/:id"
              element={
                <ProtectedRoute
                  element={CareemPromoForm}
                  addActivityId={
                    ActivitiesEnum.careem_promo__bulk_upload_careem_promo_code
                  }
                  updateActivityId={
                    ActivitiesEnum.careem_promo__update_careem_promo_code_edit
                  }
                />
              }
            />
            <Route
              path="/cars"
              element={
                <ProtectedRoute element={CarList} moduleId={ModulesEnum.cars} />
              }
            />
            <Route
              path="/car/:id"
              element={
                <ProtectedRoute
                  element={CarForm}
                  addActivityId={ActivitiesEnum.cars__add_new_car}
                  updateActivityId={ActivitiesEnum.cars__update_car_edit}
                />
              }
            />
            <Route
              path="/cities"
              element={
                <ProtectedRoute
                  element={CityList}
                  moduleId={ModulesEnum.cities}
                />
              }
            />
            <Route
              path="/customer-care"
              element={
                <ProtectedRoute
                  element={ContactusList}
                  activityId={ActivitiesEnum.contact_us_customer_care}
                />
              }
            />
            <Route
              path="/marketing"
              element={
                <ProtectedRoute
                  element={ContactusList}
                  activityId={ActivitiesEnum.contact_us_marketing}
                />
              }
            />
            <Route
              path="/career"
              element={
                <ProtectedRoute
                  element={ContactusList}
                  activityId={ActivitiesEnum.contact_us_career}
                />
              }
            />
            <Route
              path="/service-signup"
              element={
                <ProtectedRoute
                  element={ContactusList}
                  activityId={ActivitiesEnum.contact_us_vip_and_loyalty}
                />
              }
            />
            <Route
              path="/delete-account"
              element={
                <ProtectedRoute
                  element={ContactusList}
                  activityId={ActivitiesEnum.contact_us_delete_account}
                />
              }
            />
            <Route
              path="/contact-query/:id"
              element={<ProtectedRoute element={ContactusForm} />}
            />
            <Route
              path="/change-web-password"
              element={
                <ProtectedRoute
                  element={ChangeWebPassword}
                  addActivityId={ActivitiesEnum.change_password__web}
                />
              }
            />
            <Route
              path="/change-cms-password"
              element={
                <ProtectedRoute
                  element={ChangeCMSPassword}
                  moduleId={ModulesEnum.change_password}
                />
              }
            />
            <Route
              path="/pages-and-media"
              element={
                <ProtectedRoute
                  element={PagesAndMediaContent}
                  activityId={ActivitiesEnum.content_media}
                />
              }
            />
            <Route
              path="/headlines"
              element={
                <ProtectedRoute
                  element={HeadlineList}
                  addActivityId={ActivitiesEnum.content_home}
                />
              }
            />
            <Route
              path="/headline/:id?"
              element={
                <ProtectedRoute
                  element={HeadlineForm}
                  addActivityId={ActivitiesEnum.content_home}
                />
              }
            />
            <Route
              path="/banners"
              element={
                <ProtectedRoute
                  element={homeBannersList}
                  addActivityId={ActivitiesEnum.content_home}
                />
              }
            />
            <Route
              path="/banner/:id?"
              element={
                <ProtectedRoute
                  element={HomeBannerForm}
                  addActivityId={ActivitiesEnum.content_home}
                />
              }
            />
            <Route
              path="/notifications"
              element={
                <ProtectedRoute
                  element={SMSNotifications}
                  activityId={ActivitiesEnum.content_customer_sms_notification}
                />
              }
            />
            <Route
              path="/notifications-email"
              element={
                <ProtectedRoute
                  element={EmailNotifications}
                  activityId={
                    ActivitiesEnum.content_customer_email_notification
                  }
                />
              }
            />
            <Route
              path="/quotations"
              element={
                <ProtectedRoute
                  element={QuotationList}
                  addActivityId={ActivitiesEnum.pages__quotation}
                />
              }
            />
            <Route
              path="/quotation/:id?"
              element={
                <ProtectedRoute
                  element={QuotationForm}
                  addActivityId={ActivitiesEnum.pages__quotation}
                />
              }
            />
            <Route
              path="/users"
              element={
                <ProtectedRoute
                  element={UserList}
                  moduleId={ModulesEnum.customers}
                />
              }
            />
            <Route
              path="/estimation-requests"
              element={
                <ProtectedRoute
                  element={EstimationRequestList}
                  updateActivityId={ActivitiesEnum.estimation_request_all}
                  moduleId={ModulesEnum.estimation_request}
                />
              }
            />
            <Route
              path="/estimation-request/:id?"
              element={
                <ProtectedRoute
                  element={EstimationRequestForm}
                  updateActivityId={ActivitiesEnum.estimation_request_all}
                  moduleId={ModulesEnum.estimation_request}
                />
              }
            />
            <Route
              path="/kaizens"
              element={
                <ProtectedRoute
                  element={KaizenList}
                  addActivityId={ActivitiesEnum.content_kaizen}
                />
              }
            />
            <Route
              path="/kaizen/:id?"
              element={
                <ProtectedRoute
                  element={KaizenForm}
                  addActivityId={ActivitiesEnum.content_kaizen}
                />
              }
            />
            <Route
              path="/blogs"
              element={
                <ProtectedRoute
                  element={BlogList}
                  addActivityId={ActivitiesEnum.knowledge_hub_blog}
                />
              }
            />
            <Route
              path="/blog/:id?"
              element={
                <ProtectedRoute
                  element={BlogForm}
                  addActivityId={ActivitiesEnum.knowledge_hub_blog}
                />
              }
            />
            <Route
              path="/blog-tags"
              element={
                <ProtectedRoute
                  element={BlogTagsList}
                  addActivityId={ActivitiesEnum.knowledge_hub_tag}
                />
              }
            />
            <Route
              path="/blog-tag/:id?"
              element={
                <ProtectedRoute
                  element={BlogTagsForm}
                  addActivityId={ActivitiesEnum.knowledge_hub_tag}
                />
              }
            />
            <Route
              path="/order-reports"
              element={
                <ProtectedRoute
                  element={OrderReport}
                  moduleId={ModulesEnum.orders_report_lisitng}
                />
              }
            />
            <Route
              path="/orders"
              element={
                <ProtectedRoute
                  element={Orders}
                  moduleId={ModulesEnum.orders_lisitng}
                />
              }
            />
            <Route
              path="/order/:id?"
              element={
                <ProtectedRoute
                  element={OrderDetails}
                  addActivityId={ActivitiesEnum.order__detail}
                  updateActivityId={ActivitiesEnum.order__detail}
                />
              }
            />
            <Route
              path="/admins"
              element={<ProtectedRoute element={AdminList} />}
            />
            <Route
              path="/admin/:id?"
              element={<ProtectedRoute element={AdminForm} />}
            />
            <Route
              path="/roles"
              element={<ProtectedRoute element={RoleList} />}
            />
            <Route
              path="/role/:id?"
              element={<ProtectedRoute element={RoleForm} />}
            />
            <Route
              path="/promos"
              element={
                <ProtectedRoute
                  element={PromoList}
                  moduleId={ModulesEnum.promo_management}
                />
              }
            />
            <Route
              path="/promo/:id?"
              element={
                <ProtectedRoute
                  element={PromoForm}
                  addActivityId={
                    ActivitiesEnum.promo_management__add_new_promo_code
                  }
                  updateActivityId={
                    ActivitiesEnum.promo_management__update_promotion_code_edit
                  }
                />
              }
            />
            <Route
              path="/quotation-requests"
              element={
                <ProtectedRoute
                  element={QuoteRequestList}
                  moduleId={ModulesEnum.requested_quotations}
                />
              }
            />
            <Route
              path="/quotation-request/:id?"
              element={
                <ProtectedRoute
                  element={QuoteRequestForm}
                  moduleId={ModulesEnum.create_quotation}
                />
              }
            />
            <Route
              path="/required-documents"
              element={
                <ProtectedRoute
                  element={RequiredDocumentsList}
                  addActivityId={ActivitiesEnum.required_documents_all}
                />
              }
            />
            <Route
              path="/required-document/:id?"
              element={
                <ProtectedRoute
                  element={RequiredDocumentForm}
                  addActivityId={ActivitiesEnum.required_documents_all}
                />
              }
            />
            <Route
              path="/appointments"
              element={<ProtectedRoute element={AppointmentList} />}
            />
            <Route
              path="/appointment/:id?"
              element={
                <ProtectedRoute
                  element={AppointmentForm}
                  addActivityId={ActivitiesEnum.schedule_appointment_add}
                  updateActivityId={ActivitiesEnum.schedule_appointment_update}
                />
              }
            />
            <Route
              path="/schedule-emails"
              element={
                <ProtectedRoute
                  element={ScheduleEmailList}
                  moduleId={ModulesEnum.schedule_email}
                />
              }
            />
            <Route
              path="/schedule-email/:id?"
              element={
                <ProtectedRoute
                  element={ScheduleEmailForm}
                  addActivityId={ActivitiesEnum.schedule_email_add_update}
                />
              }
            />
            <Route
              path="/services"
              element={
                <ProtectedRoute
                  element={ServiceList}
                  moduleId={ModulesEnum.services}
                />
              }
            />
            <Route
              path="/service/:id?"
              element={
                <ProtectedRoute
                  element={ServiceForm}
                  addActivityId={ActivitiesEnum.services__new_service}
                  updateActivityId={
                    ActivitiesEnum.services__update_service_edit
                  }
                />
              }
            />
            <Route
              path="/service-categories"
              element={
                <ProtectedRoute
                  element={ServiceCategoryList}
                  moduleId={ModulesEnum.service_categories}
                />
              }
            />
            <Route
              path="/service-category/:id?"
              element={<ProtectedRoute element={ServiceCategoryForm} />}
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute
                  element={SettingsForm}
                  moduleId={ModulesEnum.settings}
                />
              }
            />
            <Route
              path="/variants"
              element={
                <ProtectedRoute
                  element={VariantList}
                  addActivityId={ModulesEnum.variants}
                />
              }
            />
            <Route
              path="/variant/:id?"
              element={
                <ProtectedRoute
                  element={VariantForm}
                  addActivityId={ActivitiesEnum.variant__add_variant}
                  updateActivityId={ActivitiesEnum.variant__update_variant}
                />
              }
            />
            <Route
              path="/products"
              element={
                <ProtectedRoute
                  element={ProductList}
                  addActivityId={ModulesEnum.products}
                />
              }
            />
            <Route
              path="/product/:id?"
              element={
                <ProtectedRoute
                  element={ProductForm}
                  addActivityId={ActivitiesEnum.products__new_product}
                  updateActivityId={
                    ActivitiesEnum.products__update_product_edit
                  }
                />
              }
            />
            <Route
              path="/product-categories"
              element={
                <ProtectedRoute
                  element={CategoryList}
                  addActivityId={ModulesEnum.categories}
                />
              }
            />
            <Route
              path="/product-category/:id?"
              element={
                <ProtectedRoute
                  element={CategoryForm}
                  addActivityId={ActivitiesEnum.categories__new_category}
                  updateActivityId={
                    ActivitiesEnum.categories__update_category_edit_reject
                  }
                />
              }
            />
            <Route
              path="/subscriptions"
              element={
                <ProtectedRoute
                  element={SubscriptionList}
                  moduleId={ModulesEnum.subscription_packages}
                />
              }
            />
            <Route
              path="/subscription/:id?"
              element={
                <ProtectedRoute
                  element={SubscriptionForm}
                  addActivityId={ActivitiesEnum.subscription_update_edit}
                />
              }
            />
            <Route
              path="/subscribed-offers"
              element={
                <ProtectedRoute
                  element={SubscribedOffersList}
                  moduleId={ModulesEnum.subscribed_offers}
                />
              }
            />
            <Route
              path="/subscribed-offers/:id?"
              element={
                <ProtectedRoute
                  element={SubscribedOffersForm}
                  moduleId={ActivitiesEnum.subscribed_offers_all}
                />
              }
            />
            <Route
              path="/track"
              element={
                <ProtectedRoute element={Track} moduleId={ModulesEnum.track} />
              }
            />
            <Route
              path="/track-feedbacks"
              element={
                <ProtectedRoute
                  element={TrackFeedbackList}
                  moduleId={ModulesEnum.track_repair_feedback}
                />
              }
            />
            <Route
              path="/track-feedback/:id?"
              element={
                <ProtectedRoute
                  element={TrackFeedbackForm}
                  moduleId={ModulesEnum.track}
                />
              }
            />
            <Route
              path="/upsell-recommendations"
              element={
                <ProtectedRoute
                  element={UpsellRecommendations}
                  moduleId={ModulesEnum.upsell_recommendation}
                />
              }
            />
            <Route
              path="/upsell-recommendation/:id?"
              element={<ProtectedRoute element={UpsellRecommendationForm} />}
            />
            <Route
              path="/user-activities"
              element={
                <ProtectedRoute
                  element={ActivitList}
                  moduleId={ModulesEnum.dashboard}
                />
              }
            />

            <Route
              path="/permissions-changed"
              element={<PermissionsChanged />}
            />
            <Route path="/denied" element={<AccessDenied />} />
            <Route path="/error" element={<GenericError />} />
            <Route path="/internet-error" element={<InternetError />} />
            <Route path="/session-expired" element={<SessionExpired />} />
            <Route path="*" element={<NotFound />} />

            {/* <Route path="/user/email/:access_token?" element={UserVerify} /> */}
          </Routes>

          <NotificationContainer />
        </section>

        {editingTranslationId && (
          <TranslationModal
            onCancel={() =>
              appState.setState({
                editingTranslationId: null,
                editingTranslationDefault: null,
              })
            }
            onSubmit={handleTranslationSubmit}
            show={editingTranslationId !== null}
            stringId={editingTranslationId}
            englishValue={_.get(messages.en, editingTranslationId)}
            arabicValue={_.get(messages.ar, editingTranslationId)}
          />
        )}
      </>
    );
  };

  return (
    <Subscribe to={[AppStateContainer]}>
      {(appState) => (
        <IntlProvider
          locale={appState.state.language}
          messages={appState.state.messages[appState.state.language]}
          textComponent={TranslationLabel}
        >
          {renderApp(appState)}
        </IntlProvider>
      )}
    </Subscribe>
  );
};

export default () => (
  <Provider inject={[appState]}>
    <Router>
      <App />
    </Router>
  </Provider>
);
