import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, Form } from "formik";
import { Yup } from "../../util";
import { Modal, Button } from "react-bootstrap";

export default class CommentModal extends Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={() => {
          this.props.onCancel && this.props.onCancel();
        }}
        centered
      >
        <Modal.Header closeButton={true}>
          <h4>
            <FormattedMessage
              id="global.addComment"
              defaultMessage="Add Comment"
            />
          </h4>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage
            id="global.pleaseAddYourComment"
            defaultMessage="Please Add your comment about estimation"
          />
          <Formik
            ref={(f) => (this.formik = f)}
            innerRef={(f) => (this.formik = f)}
            validationSchema={Yup.object().shape({
              comment: Yup.string().required(),
            })}
            onSubmit={(values) => {
              this.props.onSubmit && this.props.onSubmit(values.comment);
            }}
            initialValues={{
              comment: this.props.comment || "",
            }}
            render={({
              values,
              handleChange,
              touched,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form>
                  <textarea
                    name="comment"
                    value={values.comment}
                    cols="30"
                    rows="10"
                    onChange={handleChange}
                    className="form-control"
                    disabled={this.props.disabled}
                  />
                  {touched["comment"] && errors["comment"] && (
                    <p className="text-warning">{errors["comment"]}</p>
                  )}
                </Form>
              );
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            onClick={() => {
              this.props.onCancel && this.props.onCancel();
            }}
          >
            <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            onClick={() => {
              this.formik.submitForm();
            }}
            disabled={this.props.disabled}
          >
            <FormattedMessage id="global.submit" defaultMessage="Submit" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
